import React from 'react'
import SEO from '../components/seo'
import OneDimensionCA, {
  OneDimensionCAProps,
} from '../components/OneDimensionCA'

export default function OneDimensionCAPage({ location }) {
  const params = new URLSearchParams(location.search)
  const props: OneDimensionCAProps = {}
  // TODO value validation
  if (params.has('size')) props.size = Number(params.get('size'))
  if (params.has('speed')) props.speed = Number(params.get('speed'))
  if (params.has('rule')) props.speed = Number(params.get('rule'))
  if (params.has('direction')) props.direction = params.get('direction')
  return (
    <div style={{ height: '100vh', width: '100vw' }}>
      <SEO pathName={ location.pathname } />
      <OneDimensionCA {...props} />
    </div>
  )
}
